body {
    margin : 30px;
}

h1 {
    margin-top: 30px;
}

h2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

h4 {
    margin-top: 20px;
    margin-bottom: 20px;
}


p {
    margin-left: 10px;
    margin-right: 10px;
}
.row {
    margin-top : 6px;
}

.form-label {
    font-weight: bold;
    margin-top: 7px !important;
    display: block;
}

.form-static-value {
    margin-top: 7px !important;
    display: block;
}

.custom-switch {
    margin-top:6px;
}

.tabularDataItem {
    background-color: #e4e4e4;
    border-right: 1px solid white;
}

.text-center {
    text-align: center;
}

td {
    text-align: right;
    border-right: 1px solid #dee2e6;
}

td.FirstCell {
    font-weight: bold;
}

td.FinalCell {
    border-right: 0px;
}

tr:nth-child(even) {
    background-color: #e9ecef;
}


td.hidden, th.hidden {
    visibility: hidden;
}

canvas {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto!important;
    width: auto!important;
}

.red {
    color:red;
}

@media print {
    .custom-switch, .btn {
        display: none;
    }
    .container-fluid {
        width: 100%;
    }

    .chart-container canvas {
        /* transform: translateY(390px) translateX(-400px) rotate(90deg);*/
        transform: translateY(32vw) translateX(-20vh) rotate(90deg);
        /* min-height: 100vh;*/
        min-width: 100vh;
        max-width: 100vh;
        max-height: 100vh;
        height: auto!important;
        width: auto!important;
    }

    .chart-container {
        width: 100vw;
    }

    .noprint {
        display: none;
    }

    .page-break {
        page-break-before: always;
    }

    h1, h2, h3, h4, h5, h6 {
        page-break-after:avoid;
        page-break-inside:avoid
    }

    img {
        page-break-inside:avoid;
        page-break-after:avoid;
    }

    table, pre, canvas { page-break-inside:avoid }

    ul, ol, dl  { page-break-before:avoid }
}

@page {
    margin: 20px;
}
